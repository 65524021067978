import React, { useContext, useState } from "react";
import styled from "styled-components";
import { RightArrowIcon } from "./Icons";
import { MyContext } from "./SimpleForm";
import { emailValidator } from "../utils";

const Wrapper = styled.div`
	form {
		display: flex;
		align-items: center;
	}

	.input-submit input {
		padding: 0.4rem 0.6rem;
		background-color: none;
		height: 38px;
		border-left: 1px solid #1782b8;
		border-top: 1px solid #1782b8;
		border-bottom: 1px solid #1782b8;
		border-right: ${props => (props.showForm ? "none" : "1px solid #1782b8")};
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border-bottom-left-radius: ${props => (props.showForm ? "0px" : "4px")};
		border-top-left-radius: ${props => (props.showForm ? "0px" : "4px")};
		width: 200px;
	}

	.input-submit {
		display: flex;
		align-items: center;
		position: relative;
	}

	.input-submit span {
		position: absolute;
		top: 40px;
		font-size: 0.9rem;
		color: #dd2d5f;
		display: block;
		padding-top: 0.5rem;
		text-transform: initial;
	}

	.submit-icon {
		width: 38px;
		height: 38px;
		background-color: #1782b8;
		display: flex;
		align-items: center;
		padding-left: 0.4rem;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.submit-icon svg {
		fill: #fff;
		cursor: pointer;
	}

	input[disabled] {
		background: none;
		opacity: 0.9;
		cursor: not-allowed;
	}

	input:focus,
	button:focus,
	button:active {
		outline: none;
	}
`;

const SelectPhoneNumber = ({ trigger, triggerNextStep }) => {
	const { setState } = useContext(MyContext);
	const [showForm, setShowForm] = useState(true);
	const [showError, setShowError] = useState(false);
	const [email, setEmail] = useState("");

	const handleFormSubmit = async e => {
		e.preventDefault();

		if (emailValidator(email)) {
			setShowForm(false);
			setShowError(false);

			setState(prev => ({
				...prev,
				email
			}));

			triggerNextStep({
				trigger
			});
		} else {
			setShowError(true);
		}
	};

	return (
		<Wrapper showForm={showForm}>
			<form onSubmit={handleFormSubmit}>
				<div className="input-submit">
					<input
						disabled={!showForm}
						type="text"
						value={email}
						onChange={e => {
							if (!e.target.value.trim()) {
								setShowError(false);
							}
							setEmail(e.target.value);
						}}
						placeholder="Enter your email"
						required
					/>

					{showError && (
						<span className="error">Please provide valid email address</span>
					)}

					{showForm && (
						<div onClick={handleFormSubmit} className="submit-icon">
							<RightArrowIcon />
						</div>
					)}
				</div>
			</form>
		</Wrapper>
	);
};

export default SelectPhoneNumber;

//terevbdf xdfds sdf
