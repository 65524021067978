export const intialstate = [];

export const actionType = {
    SET_DATA : 'SET_DATA',
};


export const reducer = (state = intialstate,action) =>{
    switch (action.type) {
        case actionType.SET_DATA :
            return [
                ...state,
                {
                  text: action.data.message,
                  id: action.data.sender
                }

            ];
        default:
            return state
    }
};




