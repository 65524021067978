import React, { useContext } from "react";
import { MyContext } from "../SimpleForm";
import GetUserInput from "../GetUserInput";

const AlternateInputFlow = ({ triggerNextStep, trigger }) => {
	const {
		state: { exploreType }
	} = useContext(MyContext);

	return exploreType === "contact-jobs" ? (
		<GetUserInput
			placeholder="Job position"
			field="position"
			trigger={trigger}
			triggerNextStep={triggerNextStep}
		/>
	) : (
		<GetUserInput
			trigger={trigger}
			placeholder="Enter additional info"
			field="additional_info"
			trigger={trigger}
			triggerNextStep={triggerNextStep}
		/>
	);
};

export default AlternateInputFlow;
