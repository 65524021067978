import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import { RightArrowIcon } from "./Icons";
import { MyContext } from "./SimpleForm";

const Wrapper = styled.div`
	textarea {
		height: 132px;
		width: 100%;
		padding: 0.4rem 0.6rem;
		background-color: none;
		border: 1px solid #1782b8;
		border-radius: 4px;
	}

	button {
		padding: 0.4rem 0.6rem;
		background-color: none;
		border: 1px solid #1782b8;
		border-radius: 4px;
	}

	textarea[disabled] {
		background: none;
		opacity: 0.9;
		cursor: not-allowed;
	}

	textarea:focus,
	button:focus,
	button:active {
		outline: none;
	}
`;

const GetUserInput = ({ placeholder, field, trigger, triggerNextStep }) => {
	const { setState } = useContext(MyContext);
	const [input, setInput] = useState("");
	const [disable, setDisable] = useState(false);

	const handleFormSubmit = e => {
		e.preventDefault();
		setDisable(true);
		setState(prev => ({ ...prev, [field]: input }));
		triggerNextStep({
			trigger
		});
	};

	return (
		<Wrapper>
			<form onSubmit={handleFormSubmit}>
				<textarea
					required
					disabled={disable}
					type="text"
					value={input}
					onChange={e => setInput(e.target.value)}
					placeholder={placeholder}
				/>
				{!disable && <button>Submit</button>}
			</form>
		</Wrapper>
	);
};

export default GetUserInput;
