import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { BASE_URL } from "../keys/urls";
import MyContext from "./SimpleForm";
import { LoadingIcon } from "./Icons";
import { client } from "../utils"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
	animation: ${rotate} 2s linear infinite;

	svg {
		fill: #1782b8;
	}
`;

export default function Confirm({ steps, context, triggerNextStep }) {
	const { state } = React.useContext(context);
	const [loading, setLoading] = useState(true);

	React.useEffect(() => {
		if (steps["appoinment-options"].value == "book-video-consultation") {
			console.log("Ddddddddd     "+steps.gender_video_consulting)
			const data = {
				patient_name: steps.video_consulting_usernameinput?.value || "",
				patient_age: steps.video_consulting_user_age.value || "",
				phone: state.phone.phone_number,
				country: state.phone.country,
				gender: steps.gender_video_consulting?.value || "",
				doctor_id: state.doc,
				slot_id: state.sloat,
				source:"NU HOSPITAL - CHATBOT",
				opt_in:state.optIn
			};

			client(`${BASE_URL}crm/book-video-consulting`, {
				body: data
			})
				.then(res => {
					setLoading(false);
					triggerNextStep({ trigger: "finish" });
				})
				.catch(err => {
					console.log(err);
					setLoading(false);
					triggerNextStep({ trigger: "error" });
				});
		} else if (steps["appoinment-options"].value == "international-enq") {
			const apiURL = `${BASE_URL}crm/international-enquiry`;
			fetch(apiURL, {
				method: "POST",
				body: JSON.stringify({
					patient_name: steps.international_name_input.value || "",
					patient_age: steps.international_patient_age_input.value || "",
					doctor_id: state.doc,
					phone: state.phone.phone_number || "",
					country: state.phone.country || "",
					additional_info: state.additional_info || "",
					source:"NU HOSPITAL - CHATBOT",
					opt_in:state.optIn
				}),
				headers: {
					"content-type": "application/json",
					Accept: "application/json"
				}
			})
				.then(resp => resp.json())
				.then(resp => {
					if (resp.code == 201) {
						setLoading(false);
						triggerNextStep({ trigger: "finish" });
					} else {
						setLoading(false);
						triggerNextStep({ trigger: "error" });
					}
				})
				.catch(err => console.log(err));
		} else if (steps["appoinment-options"].value == "surgery_enquery") {
			console.log("Kilo 123456781234567   ", state.drop);
			const apiURL = `${BASE_URL}crm/surgery-enquiry`;
			fetch(apiURL, {
				method: "POST",
				body: JSON.stringify({
					surgery_name: state.surgery_name || "",
					additional_info: state.additional_info || "",
					user_name: steps.enq_username.value || "",
					user_age: steps.enq_patient_age_input.value || "",
					gender: steps.enq_patient_gender.value || "",
					phone: state.phone.phone_number || "",
					country: state.phone.country,
					department_id: state.dep_n || "",
					source:"NU HOSPITAL - CHATBOT",
					opt_in:state.optIn
				}),
				headers: {
					"content-type": "application/json",
					Accept: "application/json"
				}
			})
				.then(resp => resp.json())
				.then(resp => {
					if (resp.code == 201) {
						setLoading(false);
						triggerNextStep({ trigger: "surgery_thank_you" });
					} else {
						setLoading(false);
						triggerNextStep({ trigger: "error" });
					}
				})
				.catch(err => console.log(err));
		} else {
			console.log(steps);
			const apiURL = `${BASE_URL}crm/patient-appoinment`;
			console.log("SLOT: " + state.sloat);
			fetch(apiURL, {
				method: "POST",
				body: JSON.stringify({
					patient_name: steps.usernameinput.value || "",
					patient_age: steps.age.value || "",
					phone: state.phone.phone_number || "",
					country: state.phone.country || "",
					gender: steps.gender.value || "",
					// yourself_or_someone: steps.yourself_or_someone.value || "",
					slot_id: state.sloat,
					source:"NU HOSPITAL - CHATBOT",
					opt_in:state.optIn,
					type:steps["appoinment-options"].value==="find-doctor"?'Find Doctor':'Book Appointment with doctors'
				}),
				headers: {
					"content-type": "application/json",
					Accept: "application/json"
				}
			})
				.then(resp => resp.json())
				.then(resp => {
					if (resp.code == 200) {
						setLoading(false);
						triggerNextStep({ trigger: "finish" });
					} else {
						setLoading(false);
						triggerNextStep({ trigger: "error" });
					}
				})
				.catch(err => console.log(err));
		}
	}, []);

	return (
	<>
	We are taking care of your request.
	</>
	);
}
