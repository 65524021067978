import React, { useEffect, useState, useContext } from "react";
import { BASE_URL, IMAGE_URL } from "../keys/urls";
import { client } from "../utils";
import { MyContext } from "./SimpleForm";

const GetLocation = ({ steps, trigger, triggerNextStep }) => {
	const { state, setState } = useContext(MyContext);
	const [location, setLocation] = useState([]);

	const handleClick = el => {
		 var locationTag=document.getElementsByClassName('location-div')[0]
		 locationTag.innerHTML=el.branch_name
		setState(prev => ({ ...prev, locId: el.id }));

		triggerNextStep({
			trigger
		});
	};

	useEffect(() => {
		const apiURL = `${BASE_URL}branch/chatbot-branch-list`;
		client(apiURL).then(data => {
			if (steps["appoinment-options"].value === "international-enq") {
				setLocation(data.response);
			} else if (state.exploreType === "contact-lab-reports") {
				setLocation(data.response);
			} else {
				setLocation(data.response);
			}
		});
	}, []);

	return (
		<div className="location-div">
			{location.map(el => (
				<div
					key={el.id}
					className={`chat-item ${
						state.locId === el.id ? "selected-chat-item" : ""
					}`}
					onClick={() => handleClick(el)}
				>
					{el.branch_name}
				</div>
			))}
		</div>
	);
};

export default GetLocation;
