import React, { useContext, useEffect } from "react";
import { MyContext } from "./SimpleForm";

const PrivacyPolicy = ({ steps }) => {
	const { setState } = useContext(MyContext);

	useEffect(() => {
		const exploreType = steps["explore-more-options"].value;
		setState(prev => ({ ...prev, exploreType }));
	}, []);

	return (
		<div className="privacy-policy">
			Privacy policy{" "}
			<a href="https://www.chatbot.com/legal/privacy-policy/" target="_blank">
				Click Here
			</a>{" "}
			Please read and accept the privacy policy to proceed further
		</div>
	);
};

export default PrivacyPolicy;
