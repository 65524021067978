import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import SimpleForm from "./components/SimpleForm";
import DatePicker from "react-datepicker";
import { withTheme } from "styled-components";
import { OptionsIcon } from "./components/Icons";

const App = props => {
	let [showChat, setShowChat] = useState(false);
	console.log("Iam Show Chat", showChat);

	// const startChat = () => { setShowChat(true); }
	// const hideChat = () => { setShowChat(false); }

	return (
		<>
			{/* <div className = "header">
        <h2>My Application!!!</h2>
      </div> */}
			{/* <div className = "main">
        <div className ="nav">

          <h3>My Navigation</h3>
        </div>
        <div className ="content">
          <div style = {{padding:"20px"}}>
            <h1>Content of my application will go here.....</h1>
            <p>Sample content to fill the gap as much as possible. Sample content to fill the gap as much as possible.
            Sample content to fill the gap as much as possible.Sample content to fill the gap as much as possible.</p>
            <p>More content to fill the available area of the main contect. More content to fill the available area of the main contect.
            More content to fill the available area of the main contect.More content to fill the available area of the main contect. </p>
          </div>
        </div>
      </div> */}
			{/* <div className = "footer">Footer</div> */}

			<div className="bot" id="mybot">
				{/* {console.log("Iam ShowChat: ", showChat)} */}
				<SimpleForm setShowChat={setShowChat} showChat={showChat}></SimpleForm>
				{/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
				{ showChat ? (<div className="copy_rights" >
					Powered by QABS Engine<br/>
					<b>A Mestva Product &copy;</b>
					</div>) : ''}
			</div>
			
		</>
	);
};

export default App;
