export const uuid = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
		const r = (Math.random() * 16) | 0;
		const v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const client = async (endpoint, body) => {
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json"
	};

	const config = {
		method: body ? "POST" : "GET",
		headers
	};

	if (body) {
		config.body = JSON.stringify(body);
	}

	const res = await fetch(endpoint, config);
	return res.json();
};

export function phoneNumberValidator(value) {
	const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
	return value.match(phoneRegex) ? true : "Phonenumber not valid";
}

export function ageValidator(value) {
	const age = parseInt(value);
	if (String(age).length !== value.length) {
		return "Please provide valid Age";
	}

	return age >= 1 && age <= 99 ? true : "Please provide valid Age";
}

export function nameValidator(value) {
	const nameRegex = /^[a-zA-Z]/;
	const name = value.trim().toString();
	return name.match(nameRegex) ? true : "Please provide full name";
}

export function emailValidator(email) {
	const emailRegex = /\S+@\S+\.\S+/;
	return emailRegex.test(email);
}
