import React, { useEffect } from "react";
import { BASE_URL } from "../keys/urls";
import { MyContext } from "./SimpleForm";
import { client } from "../utils";

const ExploreMoreConfirm = ({ steps, trigger, triggerNextStep }) => {
	const { state } = React.useContext(MyContext);

	const {
		exploreType,
		locId,
		email,
		additional_info,
		position,
		phone: { phone_number, country }
	} = state;

	useEffect(() => {
		const data = {
			name: steps["explore-more-user-input"].value,
			email,
			phone_number,
			country,
			additional_info
		};

		if(exploreType === "contact-lab-report" || exploreType === "contact-jobs") {
			data.branch_id = locId;
		}

		if(exploreType === "contact-jobs") {
			delete data.additional_info;
			data.position = position;
		}

		client(`${BASE_URL}crm/${exploreType}`, { body: data }).then(res => {
			triggerNextStep({
				trigger
			});
		});
	}, []);

	return <div></div>;
};

export default ExploreMoreConfirm;
