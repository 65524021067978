import React from "react";
import PrivacyPolicy from "../PrivacyPolicy";
import SelectPhoneNumber from "../SelectPhoneNumber";
import AlternateInputFlow from "./AlternateInputFlow";
import GetEmail from "../GetEmail";
import ExploreMoreConfirm from "../ExploreMoreConfirm";
import GetLocation from "../GetLocation";
import { nameValidator } from "../../utils";

export default [
	{
		id: "explore-more-prompt",
		message: "Please select one of the options below",
		trigger: "explore-more-options"
	},
	{
		id: "explore-more-options",
		options: [
			{
				value: "contact-insurance",
				label: "Contact Insurance",
				trigger: "explore-more-assist"
			},
			{
				value: "contact-lab-report",
				label: "Contact Lab Reports",
				trigger: "explore-more-assist"
			},
			{
				value: "contact-jobs",
				label: "Contact Jobs",
				trigger: "explore-more-assist"
			},
			{
				value: "contact-stores",
				label: "Contact Stores",
				trigger: "explore-more-assist"
			},
			{
				value: "contact-marketing",
				label: "Contact Marketing",
				trigger: "explore-more-assist"
			}
		]
	},
	{
		id: "explore-more-end",
		message: "hi",
		end: true
	},
	{
		id: "explore-more-assist",
		message: "Sure, I'll help you",
		trigger: "explore-more-privacy-policy"
	},
	{
		id: "explore-more-privacy-policy",
		component: <PrivacyPolicy />,
		trigger: "explore-more-privacy-option"
	},
	{
		id: "explore-more-privacy-option",
		options: [
			{
				value: "agree",
				label: "Agree",
				trigger({ steps }) {
					return steps["explore-more-options"].value === "contact-lab-report" ||
						steps["explore-more-options"].value === "contact-jobs"
						? "explore-more-location-assist"
						: "explore-more-user-input-assist";
				}
			},
			{ value: "disagre", label: "No", trigger: "greet" }
		]
	},
	{
		id: "explore-more-location-assist",
		message: "Please select your location",
		trigger: "explore-more-location"
	},
	{
		id: "explore-more-location",
		component: <GetLocation trigger="explore-more-user-input-assist" />
	},
	{
		id: "explore-more-user-input-assist",
		message: "Enter your Name",
		trigger: "explore-more-user-input"
	},
	{
		id: "explore-more-user-input",
		user: true,
		validator: nameValidator,
		trigger: "explore-more-phone-input-assist"
	},
	{
		id: "explore-more-phone-input-assist",
		message: "Enter your Phone number",
		trigger: "explore-more-phone-input"
	},
	{
		id: "explore-more-phone-input",
		component: <SelectPhoneNumber trigger="explore-more-email-input-assist" />
	},
	{
		id: "explore-more-email-input-assist",
		message: "Enter your Email",
		trigger: "explore-more-email-input"
	},
	{
		id: "explore-more-email-input",
		component: <GetEmail trigger="explore-more-additional-info" />
	},
	{
		id: "explore-more-additional-info",
		message: function({ steps }) {
			return steps["explore-more-options"].value === "contact-jobs"
				? "Enter the position that you are applying"
				: "Do you want to provide any additional info?";
		},
		trigger({ steps }) {
			return steps["explore-more-options"].value === "contact-jobs"
				? "explore-more-additional-info-input"
				: "explore-more-additional-info-options";
		}
	},
	{
		id: "explore-more-additional-info-options",
		options: [
			{
				value: "yes",
				label: "Yes",
				trigger: "explore-more-additional-info-input"
			},
			{ value: "no", label: "No", trigger: "explore-more-confirm" }
		]
	},
	{
		id: "explore-more-additional-info-input",
		component: <AlternateInputFlow trigger="explore-more-confirm" />
	},
	{
		id: "explore-more-confirm",
		component: <ExploreMoreConfirm trigger="explore-more-end" />
	},
	{
		id: "explore-more-end",
		message: "Your data successfully submitted thank you for chatting with us",
		end: true
	}
];
