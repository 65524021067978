import React, { useEffect, useState, useContext, useRef } from "react";
import Carousel, { arrowsPlugin } from "@brainhubeu/react-carousel";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Icon } from "react-fa";
import { BASE_URL, IMAGE_URL } from "../keys/urls";
import "@brainhubeu/react-carousel/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { client } from "../utils";

const DocCard = ({
	data,
	triggerNextStep,
	context,
	setDes,
	setSelected,
	saveData,
	video,
	enquire
}) => {
	const { state, setState } = useContext(context);
	const [currentTab, setCurrentTab] = useState(1);
	const [slots, setSlots] = useState([]);
	const [dte, setDte] = useState("");
	const [avil, setAvil] = useState([]);

	const [showSlots, setShowSlots] = useState(false);

	// Handle Tab
	const handleTab = tab => {
		setCurrentTab(tab);
	};

	useEffect(() => {
		console.log(video);
		let date = new Date();
		let formated =
			date.getFullYear() +
			"-" +
			(date.getMonth() + 1 < 10
				? "0" + (date.getMonth() + 1)
				: date.getMonth() + 1) +
			"-" +
			(date.getUTCDate()<10 ? "0"+(date.getUTCDate()):(date.getUTCDate()));
		getState(formated);
		setDte(new Date(formated));

		let dddd = data.availabe_dates.map(item => new Date(item));
		console.log(dddd);
		setAvil(dddd);
	}, []);

	const getState = date => {
		//
		const apiURL = `${BASE_URL}crm/fetch-slots/${data.id}?date=${date}`;
		fetch(apiURL, {
			method: "GET",
			headers: {
				"content-type": "application/json",
				Accept: "application/json"
			}
		})
			.then(resp => resp.json())
			.then(resp => {
				let temp = {};
				resp.response.map(item => {
					if (item.from_time.split(":")[0] <= 12) {
						if (temp["mor"]) {
							temp["mor"].push({
								id: item.id,
								txt: `${moment("2020-01-01T" + item.from_time).format(
									"hh.mm a"
								)} - ${moment("2020-01-01T" + item.to_time).format("hh.mm a")}`
							});
						} else {
							temp["mor"] = [
								{
									id: item.id,
									txt: `${moment("2020-01-01T" + item.from_time).format(
										"hh.mm a"
									)} - ${moment("2020-01-01T" + item.to_time).format(
										"hh.mm a"
									)}`
								}
							];
						}
					} else if (item.from_time.split(":")[0] <= 16) {
						if (temp["aft"]) {
							temp["aft"].push({
								id: item.id,
								txt: `${moment("2020-01-01T" + item.from_time).format(
									"hh.mm a"
								)} - ${moment("2020-01-01T" + item.to_time).format("hh.mm a")}`
							});
						} else {
							temp["aft"] = [
								{
									id: item.id,
									txt: `${moment("2020-01-01T" + item.from_time).format(
										"hh.mm a"
									)} - ${moment("2020-01-01T" + item.to_time).format(
										"hh.mm a"
									)}`
								}
							];
						}
					} else {
						if (temp["eve"]) {
							temp["eve"].push({
								id: item.id,
								txt: `${moment("2020-01-01T" + item.from_time).format(
									"hh.mm a"
								)} - ${moment("2020-01-01T" + item.to_time).format("hh.mm a")}`
							});
						} else {
							temp["eve"] = [
								{
									id: item.id,
									txt: `${moment("2020-01-01T" + item.from_time).format(
										"hh.mm a"
									)} - ${moment("2020-01-01T" + item.to_time).format(
										"hh.mm a"
									)}`
								}
							];
						}
					}
				});
				setSlots(temp);
				// this.setState({ slots:})
			})
			.catch(err => console.log(err));
	};

	const renderSloats = () => {
		let drd = [];
		if (currentTab == 1) drd = slots["mor"];
		if (currentTab == 2) drd = slots["aft"];
		if (currentTab == 3) drd = slots["eve"];
		if (!drd?.length) return <div>No Slots available ! </div>;
		return drd?.map(item => {
			return (
				<div
					className="item"
					onClick={() => {
						setSelected(item.txt);
						setState(prev => {
							return {
								...prev,
								doc: data.id,
								sloat: item.id,
								sloatTxt: item.txt,
								deptName: data.department_name.department,
								docName: data.doctor_name,
								dte
							};
						});
						setDes(true);
						triggerNextStep({
							trigger: "confirm"
						});
					}}
				>
					{item.txt}
				</div>
			);
		});
	};

	const renderContent = () => {
		if (video && showSlots) {
			return (
				<div>
					<button
						class="book-btn"
						onClick={() => {
							setState(prev => {
								return { ...prev, doc: data.id };
							});
							setDes(true);
							triggerNextStep({
								trigger: "option_video_consulting"
							});
						}}
					>
						{`${enquire ? "Enquire" : "Book"} This Doctor`}
					</button>
				</div>
			);
		} else if (showSlots) {
			return (
				<div className="slots">
					<div className="date-container">
						<div style={{ fontSize: 10 }}></div>
						<div style={{ flexDirection: "column" }}>
							<div>
								<Icon name="calendar" style={{ marginRight: 10 }} />
								<DatePicker
									selected={dte}
									dateFormat="yyyy-MM-dd"
									includeDates={avil}
									className="date-feild"
									onChange={date => {
										let d = moment(date).format("YYYY-MM-DD");
										setDte(new Date(d));
										getState(d);
									}}
									popperPlacement="bottom-end"
								/>
							</div>
							<div style={{ fontSize: 13 }}>Search Date</div>
						</div>
					</div>
					<div className="tab-container">
						<div className="tab">
							<div
								className={currentTab == 1 ? "item active" : "item"}
								onClick={() => handleTab(1)}
							>
								Morning
							</div>
							<div
								className={currentTab == 2 ? "item active" : "item"}
								onClick={() => handleTab(2)}
							>
								Afternoon
							</div>
							<div
								className={currentTab == 3 ? "item active" : "item"}
								onClick={() => handleTab(3)}
							>
								Evening
							</div>
						</div>
					</div>
					<div style={{ height: 129, overflowY: "scroll", marginTop: 14 }}>
						<div className="slots-area">{renderSloats()}</div>
					</div>
				</div>
			);
		} else if (enquire) {
			return (
				<div className="appointment-know-more">
					<button
						onClick={() =>{
							setState(prev => {
								return {
									...prev,
									doc: data.id
								};
							});
							triggerNextStep({ trigger: "option_video_consulting" })
						}
						}
					>
						Enquire now
					</button>
					<button
						onClick={() => {
							if (data.profile_url) {
								window.open(data.profile_url, "_blank");
							} else {
								window.open("https://www.nuhospitals.com/doctors", "_blank");
							}
						}}
					>
						Visit profile
					</button>
				</div>
			);
		} else {
			return (
				<div className="appointment-know-more">
					<button onClick={() => setShowSlots(true)}>Book Appointment</button>
					<button
						onClick={() => {
							if (data.profile_url) {
								window.open(data.profile_url, "_blank");
							} else {
								window.open("https://www.nuhospitals.com/doctors", "_blank");
							}
						}}
					>
						Visit profile
					</button>
				</div>
			);
		}
	};

	return (
		<div className="doc-card">
			<div className="doc-top">
				<img src={IMAGE_URL + data.profile_photo} className="doc-img" />
				<div className="doc-meta">
					<div className="dep">{data.department_name.department}</div>
					<div className="name">{data.doctor_name}</div>
					<div className="qu">{data.qualification.slice(0, 33)}</div>
					<div className="qu">
						{data.qualification.length > 33
							? data.qualification.slice(33, 66)
							: ""}
					</div>
					<div className="qu">
						{data.qualification.length > 66
							? data.qualification.slice(66, data.qualification.length + 1)
							: ""}
					</div>
				</div>
			</div>
			{renderContent()}
		</div>
	);
};

export default function Slots({ id, triggerNextStep, context, steps }) {
	const { state, setState } = useContext(context);
	const [data, setData] = useState([]);
	const [select, setSelected] = useState("");
	const [video, setVideo] = useState(false);
	const [enquire, setEnquire] = useState(false);
	const [des, setDes] = useState(false);
	const [slidePos, setSlidePos] = useState(0);
	const [avil, setAvil] = useState([]);

	const docBack = useRef([]);

	useEffect(() => {
		if (
			steps["appoinment-options"].value == "book-video-consultation1" ||
			steps["appoinment-options"].value == "international-enq"
		) {
			setVideo(true);
		} else {
			setVideo(false);
		}

		if (steps["appoinment-options"].value == "international-enq") {
			setEnquire(true);
		}

		const apiURL = `${BASE_URL}crm/department/${state.dep_n}/doctors`;

		client(apiURL).then(data => {
			docBack.current = typeof data.response == "string" ? [] : data.response;
			setData(data.response);
		});
	}, []);

	const renderItems = () => {
		if (typeof data == "string") {
			return <div style={{ fontSize: 12 }}>{data}</div>;
		}

		return data?.map(item => {
			return (
				<DocCard
					data={item}
					triggerNextStep={triggerNextStep}
					context={context}
					setDes={setDes}
					setSelected={setSelected}
					video={video}
					enquire={enquire}
				/>
			);
		});
	};

	return (
		<div className="appoinment-container">
			{des && (
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "#ffffff01",
						zIndex: 9999
					}}
				></div>
			)}

			<div className="apo-header">
				<img src={require("../img/calendar.png")} className="calender-icon" />
				<div className="header-title">Appointment Centre</div>
			</div>

			<div className="apo-sub-header">
				<div className="apo-date">{select}</div>
				<div className="search-box">
					<div class="full_icon">
						<div className="search-icon-container" className="empty_container">
							&nbsp;
						</div>
						<div className="search-icon-container">
							<img
								src={require("../img/searchicon.png")}
								className="search-icon"
							/>
						</div>
					</div>
					<input
						type="text"
						placeholder="Search by Doctors"
						className="search-feild"
						onChange={e => {
							let nn = docBack.current.filter(doc => {
								return doc.doctor_name
									.toLowerCase()
									.includes(e.target.value.toLowerCase());
							});
							setData(nn);
						}}
					/>
				</div>
			</div>
			<div className="doc-container">
				<img
					src={require("../img/leftside.png")}
					className="nav-btn prev"
					onClick={() =>
						setSlidePos(prv => {
							if (prv > 0) return prv - 1;
							else return prv;
						})
					}
				/>
				<Carousel value={slidePos} onChange={val => setSlidePos(val)}>
					{renderItems()}
				</Carousel>

				<img
					src={require("../img/rightside.png")}
					className="nav-btn next"
					onClick={() =>
						setSlidePos(prv => {
							if (prv < data.length - 1) return prv + 1;
							else return prv;
						})
					}
				/>
			</div>
		</div>
	);
}
