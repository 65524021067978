import React, { useEffect, useState, useContext } from "react";
import { BASE_URL, IMAGE_URL } from "../keys/urls";
import { client } from "../utils";
import { MyContext } from "./SimpleForm";

const GetSurgeries = ({ trigger, triggerNextStep }) => {
	const { state, setState } = useContext(MyContext);
	const [surgeries, setSurgeries] = useState([]);

	const handleSelection = e => {
		if(e.target.value === "others") {
			triggerNextStep({ trigger: "enter_surgery_name_message" });
		} else {
			setState(prev => ({ ...prev, surgery_name: e.target.value }));

			triggerNextStep({
				trigger
			});
		}
	};

	useEffect(() => {
		const apiURL = `${BASE_URL}appoiment-portal/get-surgery-info/${state.dep_n}`;
		client(apiURL).then(data => setSurgeries(data.response));
	}, []);

	return (
		<>
			<select id="surgeries" onChange={handleSelection}>
			<option>-- Select --</option>
				{surgeries.map(el => (
					<option key={el.id} value={el.surgery_name}>
						{el.surgery_name}
					</option>
				))}
				
				<option value="others">Others</option>
			</select>
		</>
	);
};

export default GetSurgeries;
