import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ChatBot, { Loading } from 'react-simple-chatbot';

export default class ContactNumber extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            result:'',
            trigger:false,
            status:''
        };
        this.triggertNext = this.triggetNext.bind(this)

    }
    componentWillMount()
    {
        const self = this;
        const {steps} = this.props;
        const phoneNumber = steps.contactnumber.value;
        const apiURL = `http://127.0.0.1:8000/crm/api/patient-search?phone_number=${phoneNumber}`
        
        const xhr = new XMLHttpRequest();
        xhr.addEventListener('readystatechange', readyStateChange);
        function readyStateChange() {
            if (this.readyState === 4) {
              const data = JSON.parse(this.responseText)
              console.log('Hello Iam ',data)
              const bindings = data.message;
              if (bindings && bindings.length > 0) {
                self.setState({ loading: false, result: bindings,status:data.status });
              } else {
                self.setState({ loading: false, result: 'Not found.' });
              }
            }
          }
      
          xhr.open('GET', apiURL);
          xhr.send();
        }

    triggetNext() {
        if(this.state.status ==='user-found')
        {
            this.setState({ trigger: 'user-found' }, () => {
                this.props.triggerNextStep();
              });

        }
        else{
        this.setState({ trigger: 'user-not-found' }, () => {
          this.props.triggerNextStep();
        });
      }
    }
    
      render(){
          const {trigger,loading,result} = this.state;
          return(
              <div>
                  {loading? <Loading/>:result}
                  {window.sessionStorage.setItem("status", this.state.status)}
                  {
                    !loading &&
                    <div
                        style={{
                        textAlign: 'center',
                        marginTop: 20,
                        }}
                    >
                        {
                        trigger &&
                        <button
                            onClick={() => this.triggetNext()}
                        >
                            Try Again
                            {console.log("Hello Status:   ",this.state.status)}
                        </button>
                        }
                        
                    </div>
                    }
              </div>
          );
      }

}

ContactNumber.propTypes = {
    steps: PropTypes.object,
    triggerNextStep: PropTypes.func,
  };
  
  ContactNumber.defaultProps = {
    steps: undefined,
    triggerNextStep: undefined,
  };
  