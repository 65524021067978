import React,{useState,useEffect,useRef} from 'react';
import './OnlineChat.css';
import botImage from './../img/bot2.png';
// import {io} from 'socket.io-client';
// import publicIp from "public-ip";
import {UserValue} from './../UserProvider';
import {actionType} from './reducer';
import axios from 'axios';
import { RightArrowIcon } from "./Icons";

const OnlineChat = ({triggervalue}) => {
    const [input,setInput] = useState('');
    const [userId,setuserId] = useState(null);
    const [name,setName] = useState('');
    const [mobile,setMobile] = useState('');
    const [mail,setMail] = useState('');
    const [err,setErr] = useState(false);
    const [ip,setIp] = useState(null);
    const socket=useRef();
    const sender_id=1;
    const [messages,dispatch] = UserValue();
    // const [messages,setMessages] = useState([]);
    // const url ="ws://mspr2app.vlabsinc.com:90/ws/chat-one-to-one-async";
    const url ="ws://localhost:8000/ws/chat-website-to-admin-async";
    const final_url =  url+'/'+userId+'/2/';
    const  handleSubmit = (e) =>{
        e.preventDefault();
        socket.current.send(input);
        setInput('');
    };
    const  createUser = (e) =>{
        let ruserId= Math.floor(Math.random() * 10000);
        console.log(ruserId);
        if(mail == "" || mobile == "" || name ==""){
            setErr(true);
        }else{
            setErr(false);
            localStorage.setItem("ruserid", ruserId);
            setuserId(ruserId);
            const udata = { full_name: name,mobile:mobile,email:mail,unique_id:ruserId};
            axios.post('http://localhost:8000/user-create-from-website/', udata)
                .then(response => {
                    // unique_id: 7886, agent_id: 41
                    //    console.log("respo_raghvendra=",response['data']['unique_id']);
                    let final_url_log =  url+'/'+ruserId+'/2/';
                    //   let final_url_log =  url+'/'+response['data']['unique_id']+'/'+response['data']['agent_id']+'/';
                    socket.current=  new WebSocket(final_url_log);
                    socket.current.addEventListener('open', (evt) => {
                        console.log(evt);
                    });
                    socket.current.addEventListener('message', (evt) => {
                        console.log(messages);
                        var data = JSON.parse(evt.data);
                        console.log(data);
                        dispatch({
                            type : actionType.SET_DATA,
                            data: data
                        });
                    });
                });

        }
        e.preventDefault();
    };

    // const getClientIp = async () => await publicIp.v4({
    //     fallbackUrls: [ "https://ifconfig.co/ip" ]
    // });

    useEffect(()=>{
        // socket.current=io("ws://localhost:8900");
        let suserid= localStorage.getItem('ruserid');
        if(suserid){
            setuserId(suserid);
        }
        const el1 = document.querySelector('.rsc-footer');
        el1.classList.add('footer_hide');
        console.log(suserid);
        console.log(userId);
        if(suserid != null){
            let final_url_log =  url+'/'+suserid+'/2/';
            // let final_url_log =  url+'/1/2/';
            console.log(final_url_log);
            socket.current=  new WebSocket(final_url_log);
        }
        if(messages.length == 0){
            let new_msh = {
                message : 'hey we are connecting to our agent please be patient...',
                sender : 2
            }
            dispatch({
                type : actionType.SET_DATA,
                data: new_msh
            });
        }
    },[]);

    useEffect(()=>{
        let suserid= localStorage.getItem('ruserid');
        if(suserid){
            socket.current.addEventListener('open', (evt) => {
                console.log(evt);
            });
            socket.current.addEventListener('message', (evt) => {
                console.log(messages);
                var data = JSON.parse(evt.data);
                console.log(data);
                dispatch({
                    type : actionType.SET_DATA,
                    data: data
                });
            });
            return () =>{
                socket.current.addEventListener('close', (event) => {
                    console.log('The connection has been closed successfully.');
                });
                socket.current.close();
            }
        }

    },[socket]);



    console.log(messages);
    return (
        <div className="onlineChat">
            <div className="message">
                {userId ?
                    messages?.map((message,i) => {
                        return (
                            <p key={i} className={`chat__message ${message.id == userId ? 'receiver_msg':'sender_msg'}`}>
                                <div className="sc-pNWdM kibAmI rsc-ts rsc-ts-bot">
                                    <div className="sc-fujyAs iBXIYa rsc-ts-image-container agent_name">
                                        {message.id != userId ? 'Agent:' : ''}
                                        {/* <img src={botImage}   className="sc-iCoGMd lhjQXx rsc-ts-image" alt="avatar"/> */}
                                    </div>

                                    <div className={`sc-gKAaRy geFjVe rsc-ts-bubble ${message.id == userId ? 'content_sender':'content_receiver'}`}> {message.text}</div>
                                </div>
                            </p>
                        )
                    })
                    :
                    <form className="userinput">
                        {err ?<div className="errMsg">  Please fill name,mobile number and email</div>: <div className="form_center"> Please fill your details  </div>}
                        <input
                            value={name}
                            onChange={(e)=>setName(e.target.value)}
                            placeholder="Name"
                            type="text"
                            required={true}
                            autoComplete={false}
                        />
                        <input
                            value={mail}
                            onChange={(e)=>setMail(e.target.value)}
                            placeholder="Email"
                            text="mail"
                            required={true}
                            autoComplete={false}
                        />
                        <input
                            value={mobile}
                            onChange={(e)=>setMobile(e.target.value)}
                            placeholder="Mobile"
                            text="text"
                            required={true}
                            autoComplete={false}
                        />
                        <button type="submit" onClick={createUser}>Submit</button>
                    </form>
                }




            </div>
            {
                userId &&
                <div className="input_text">
                    <form onSubmit={handleSubmit}>
                        <input value={input}  placeholder="Type a query"  className="sc-fnVZcZ btZjys rsc-input " type="text" onChange={e => setInput(e.target.value)} />
                        <button type="submit" className="sc-fFSPTT fagAKt lexRPv rsc-submit-button" >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 500 500"><g><g><polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75"></polygon></g></g></svg>
                        </button>
                    </form>
                </div>
            }

        </div>
    );
};




export default OnlineChat;
