import React, { useEffect, useState, useContext } from "react";
import moment from "moment";

export default function Departments({ setData, triggerNextStep, context }) {
	const { state } = useContext(context);

	return (
		<div>
			<div className="chat-item-long">
				<div style={{ textAlign: "left" }}>
					<h4>Reviewing your appointment, we will get back shortly</h4>
					Doctor: {state.docName} <br />
					Department: {state.deptName} <br />
					Date: {moment(state.dte).format("DD-MM-YYYY")} <br />
					Time: {state.sloatTxt.toUpperCase()}
				</div>
			</div>
		</div>
	);
}
