import React, { useEffect, useState, useContext } from "react";
import { BASE_URL, IMAGE_URL } from "../keys/urls";
import { client } from "../utils";
import { MyContext } from "./SimpleForm";

const GetDepartments = ({ trigger, triggerNextStep }) => {
	const { state, setState } = useContext(MyContext);
	const [departments, setDepartments] = useState([]);

	const handleClick = el => {
		var locationTag=document.getElementsByClassName('dep-div')[0]
		locationTag.innerHTML=el.department
		setState(prev => ({ ...prev, dep_n: el.id }));

		triggerNextStep({
			trigger
		});
	};

	useEffect(() => {
		const apiURL = `${BASE_URL}appoiment-portal/department-list?branch_id=${state.locId}`;
		client(apiURL).then(data => setDepartments(data.response));
	}, []);

	return (
		<div className="dep-div">
			{departments.map(el => (
				<div
					key={el.id}
					className={`chat-item ${
						state.dep_n === el.id ? "selected-chat-item" : ""
					}`}
					onClick={() => handleClick(el)}
				>
					{el.department}
				</div>
			))}
		</div>
	);
};

export default GetDepartments;
