// export  const BASE_URL = "http://dev01-api.wegren.com/api/";
// export const IMAGE_URL = "http://dev01-api.wegren.com/";


// export  const BASE_URL = "https://endpoint.in.ext.con.nufertility.com/api/";
// export const IMAGE_URL = "https://endpoint.in.ext.con.nufertility.com/";


export  const BASE_URL = "https://endpoint.in.ext.con.nuhospitals.com/api/";
export const IMAGE_URL = "https://endpoint.in.ext.con.nuhospitals.com/";
